exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-development-js": () => import("./../../../src/pages/work/development.js" /* webpackChunkName: "component---src-pages-work-development-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-live-js": () => import("./../../../src/pages/work/live.js" /* webpackChunkName: "component---src-pages-work-live-js" */),
  "component---src-pages-work-mdx-slug-js": () => import("./../../../src/pages/work/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-work-mdx-slug-js" */),
  "component---src-pages-work-photo-js": () => import("./../../../src/pages/work/photo.js" /* webpackChunkName: "component---src-pages-work-photo-js" */),
  "component---src-pages-work-video-js": () => import("./../../../src/pages/work/video.js" /* webpackChunkName: "component---src-pages-work-video-js" */)
}

